import React, { useState, useEffect } from 'react';
import { Field, useFormikContext } from 'formik';
import FormikInputField from 'components/common/Fields/FormikInputField';
import FormikSelectField from 'components/common/Fields/FormikSelectField';
import FileDragDrop from 'components/FileDragDrop';
import { Card, CardBody, Col, Label, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { UPDATE_FILE } from '../Mutation';
import MinioFileUpload from 'components/MinioFileUpload';
import { getUser } from 'utils/UserDetails';
import CombineErrorMessage from 'components/CombineError';
import closeIcon from 'assets/icons/close.svg';
import { useMediaQuery } from 'react-responsive';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';
import ImageModal from './ImageModal';

const types = [
  "Canadian driver's licence",
  'Canadian passport',
  'Certificate of Canadian Citizenship',
  'Certification of Naturalization',
  'Permanent resident card',
  'Immigration Canada Document',
  'Canadian Birth Certificate',
  'Social Insurance Number Card',
  'Certificate of Indian Status',
  'Provincially Issued ID'
];

const Identification = ({
  formKey,
  title = 'Identification',
  children,
  secondary = false,
  identyTypesKey,
  isNewData
}) => {
  const [state, setState] = useState({
    front: '',
    frontLoading: false,
    frontSignedUrl: '',
    back: '',
    backLoading: false,
    backSignedUrl: ''
  });
  const [imgModal, setImageModal] = useState({
    isOpen: false,
    imgURL: null
  });

  const [hasIdentyError, setHasIdentyError] = useState(false);
  const [hasDocError, setHasDocError] = useState(false);

  const { forntDoc, backDoc, identyType, identyNum } = formKey;
  const { setFieldValue, values, errors, touched } = useFormikContext();
  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  });

  useEffect(() => {
    const isError = checkError();
    const docError = checkDocError();
    setHasIdentyError(isError);
    setHasDocError(docError);
  }, [errors, touched]);

  const [uploadFile] = useMutation(UPDATE_FILE);
  const user = getUser();

  const { front, frontSignedUrl, back, frontLoading, backLoading, backSignedUrl } = state;

  const checkError = () => {
    return (errors[identyType] && touched[identyType]) || (errors[identyNum] && touched[identyNum]);
  };

  const checkDocError = () => {
    return (errors[forntDoc] && touched[forntDoc]) || (errors[backDoc] && touched[backDoc]);
  };

  const onUploadFront = async (files) => {
    try {
      const result = await generatePresignedUrl(files[0]);
      setState((pre) => ({
        ...pre,
        frontSignedUrl: result.signedUrl,
        frontLoading: true,
        front: files[0]
      }));
      setFieldValue(forntDoc, result.sourcePath);
    } catch (error) {
      console.log(error);
    }
  };

  const onUploadBack = async (files) => {
    try {
      const result = await generatePresignedUrl(files[0]);
      setState((pre) => ({
        ...pre,
        backSignedUrl: result.signedUrl,
        backLoading: true,
        back: files[0]
      }));
      setFieldValue(backDoc, result.sourcePath);
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseIdenty = (role) => {
    switch (role) {
      case 'front':
        setFieldValue(forntDoc, '');
        setState((pre) => ({ ...pre, front: '', frontLoading: true, frontSignedUrl: '' }));
        return;
      case 'back':
        setFieldValue(backDoc, '');
        setState((pre) => ({ ...pre, back: '', backLoading: true, backSignedUrl: '' }));
        return;
      default:
        return;
    }
  };

  const generatePresignedUrl = async (file) => {
    try {
      const { data } = await uploadFile({
        variables: {
          fileName: file.name,
          fileType: 'doc',
          userId: user.id
        }
      });
      return data.fileApiPresignedUrl;
    } catch (error) {}
  };

  const getDocBlobURL = (key, name) => {
    return state[key] && typeof state[key] === 'object'
      ? URL.createObjectURL(state[key])
      : values[name];
  };

  const renderFrontIdenty = () => {
    return (
      <div>
        {frontLoading ? (
          <MinioFileUpload
            signedURL={frontSignedUrl}
            file={front}
            onClose={() => setState((pre) => ({ ...pre, frontLoading: false }))}
          />
        ) : (
          <div className="upload-image-container">
            <span onClick={() => onCloseIdenty('front')} className="image-cross-btn">
              <img src={closeIcon} alt="closeIcon" />
            </span>
            <img
              onClick={() => onImageShow(getDocBlobURL('front', forntDoc))}
              src={getDocBlobURL('front', forntDoc)}
              alt="Document"
              // width={isMobile ? 313 : 340}
              height={200}
              className={
                frontLoading ? 'd-none' : 'd-block me-3 mb-3 object-fit-contain rounded w-100'
              }
            />
          </div>
        )}
      </div>
    );
  };

  const renderBackIdenty = () => {
    return (
      <div>
        {backLoading ? (
          <MinioFileUpload
            signedURL={backSignedUrl}
            file={back}
            onClose={() => setState((pre) => ({ ...pre, backLoading: false }))}
          />
        ) : (
          <div className="upload-image-container">
            <span onClick={() => onCloseIdenty('back')} className="image-cross-btn">
              <img src={closeIcon} alt="closeIcon" />
            </span>
            <img
              src={getDocBlobURL('back', backDoc)}
              onClick={() => onImageShow(getDocBlobURL('back', backDoc))}
              alt="Document"
              height={200}
              className={
                backLoading ? 'd-none' : 'd-block me-3 mb-3 object-fit-contain rounded w-100'
              }
            />
          </div>
        )}
      </div>
    );
  };

  const getIdentyType = () => {
    const { primary: primaryType, secondary: secondaryType } = identyTypesKey;
    const valueType = secondary ? values[primaryType] : values[secondaryType];
    return types.filter((item) => item !== valueType);
  };

  const onImageShow = (url) => {
    setImageModal((pre) => ({
      ...pre,
      imgURL: url || null,
      isOpen: !pre.isOpen
    }));
  };

  return (
    <div>
      {imgModal.isOpen ? (
        <ImageModal
          modal={imgModal.isOpen}
          docsImg={imgModal.imgURL}
          toggle={() => onImageShow(null)}
        />
      ) : null}
      <Card className="mt-4">
        <CardBody className="p-4">
          {hasIdentyError || hasDocError ? (
            <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
          ) : null}
          <div className="d-flex justify-content-between align-items-center p-0">
            <h3 className="card-heading">{title}</h3>
            {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
          </div>
          <div>{children}</div>
          <Row className="input-spacing">
            <Col lg={6} md={6} sm={12} className={isMobile ? 'input-spacing' : ''}>
              <Field
                size="lg"
                id="identyType"
                name={identyType}
                type="text"
                component={FormikSelectField}
                options={getIdentyType()}
                label="Identification Type"
                isError={false}
              />
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Field
                size="lg"
                id="identyNumber"
                name={identyNum}
                type="text"
                component={FormikInputField}
                label="Identification Number"
                isError={false}
              />
            </Col>
          </Row>
          <div className="input-spacing">
            <div>
              <Label>Upload Identification</Label>
            </div>
            <div
              className="row"
              //  className={values[forntDoc] && values[backDoc] ? `${!isMobile ? 'd-flex' : ''}` : ''}
            >
              {values[forntDoc] ? (
                <div className="col-md-6">{renderFrontIdenty()}</div>
              ) : (
                <Col md="12" className="mb-3">
                  <FileDragDrop
                    text="Drag the front side file here or"
                    uploadFile={onUploadFront}
                    isError={errors[forntDoc] && touched[forntDoc]}
                  />
                </Col>
              )}
              {values[backDoc] ? (
                <div className="col-md-6">{renderBackIdenty()}</div>
              ) : (
                <div>
                  <FileDragDrop
                    text="Drag the back side file here or"
                    uploadFile={onUploadBack}
                    isError={errors[backDoc] && touched[backDoc]}
                  />
                </div>
              )}
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Identification;
