import { gql } from '@apollo/client';

const GET_USER_DETAIL_BY_ID = gql`
  query getUserPersonalDetailById($userId: Int!) {
    getUserPersonalDetailById(userId: $userId) {
      approvedStatus
      id
      email
      roleId
      firstName
      lastName
      preferredName
      countryCode
      phoneNumber
      sign
      isVerified
      isEmailVerified
      customUrl
      invitedBy
      streetAddress1
      unitNumber1
      city1
      province1
      country1
      postalCode1
      streetAddress2
      unitNumber2
      city2
      province2
      country2
      postalCode2
      maritalStatus
      occupation
      accountType
      licenseNumber
      licensedFsra
      workedWithMA
      identificationType
      identificationNumber
      identificationDocs
      identificationBackDocs
      secondaryIdentificationType
      secondaryIdentificationNumber
      secondaryIdentificationDocs
      secondaryIdentificationBackDocs
      profilePicture
      approveSections
      isAccountVerified
      isEsign
      isBrokerageInfo
      isPersonalInfo
      isBusinessInfo
      isLendingCreteria
      confirmAndSign
      createdAt
      updatedAt
      isCookiePolicy
      isPrivacyPolicy
      isTermsService
      isSameAddress
      mortgageAdmin
      mortgageAdminId
      userNote
      requestStatus
      finalApprovalComment
      enableNewsOffers
      isUnderReviewed
      approveRequiredSections
      isFilofixPresent
    }
  }
`;

const GET_LENDING_CRETERIA = gql`
  query getLendingCreteria($userId: Int) {
    getLendingCreteria(userId: $userId)
  }
`;

const UPDATE_BENEFICIARY = gql`
  mutation UpdateBeneficiary(
    $userId: Int!
    $beneficiaryId: Int!
    $beneficiaryLegalName: String
    $beneficiaryLastName: String
    $beneficiaryRelationAccount: String
    $beneficiaryPhoneNo: String
    $isUpdating: Boolean
  ) {
    updateBeneficiary(
      userId: $userId
      beneficiaryId: $beneficiaryId
      beneficiaryLegalName: $beneficiaryLegalName
      beneficiaryLastName: $beneficiaryLastName
      beneficiaryRelationAccount: $beneficiaryRelationAccount
      beneficiaryPhoneNo: $beneficiaryPhoneNo
      isUpdating: $isUpdating
    )
  }
`;

const REMOVE_BENEFICARY = gql`
  mutation DeleteBeneficiary($userId: Int!, $beneficiaryId: Int!) {
    deleteBeneficiary(userId: $userId, beneficiaryId: $beneficiaryId)
  }
`;

const UPDATE_DIRECTORS = gql`
  mutation UpdateDirectorDetails(
    $userId: Int!
    $directorId: Int!
    $directorLegalName: String
    $directorLastName: String
    $directorPosition: String
    $directorPhoneNo: String
    $isUpdating: Boolean
  ) {
    updateDirectorDetails(
      userId: $userId
      directorId: $directorId
      directorLegalName: $directorLegalName
      directorLastName: $directorLastName
      directorPosition: $directorPosition
      directorPhoneNo: $directorPhoneNo
      isUpdating: $isUpdating
    )
  }
`;

const UPDATE_JOINT_MEMBER = gql`
  mutation updateJointMember(
    $userId: Int!
    $jointMemberId: Int!
    $jointLegalName: String
    $jointLastName: String
    $jointRelationAccount: String
    $jointPhoneNo: String
    $isUpdating: Boolean
  ) {
    updateJointMember(
      userId: $userId
      jointMemberId: $jointMemberId
      jointLegalName: $jointLegalName
      jointLastName: $jointLastName
      jointRelationAccount: $jointRelationAccount
      jointPhoneNo: $jointPhoneNo
      isUpdating: $isUpdating
    )
  }
`;

const UPDATE_TRUST_MEMBER = gql`
  mutation updateTrustMember(
    $userId: Int!
    $trustMemberId: Int!
    $trustLegalName: String
    $trustLastName: String
    $trustRelationAccount: String
    $trustPhoneNo: String
    $isUpdating: Boolean
  ) {
    updateTrustMember(
      userId: $userId
      trustMemberId: $trustMemberId
      trustLegalName: $trustLegalName
      trustLastName: $trustLastName
      trustRelationAccount: $trustRelationAccount
      trustPhoneNo: $trustPhoneNo
      isUpdating: $isUpdating
    )
  }
`;

const REMOVE_DIRECTOR = gql`
  mutation DeleteDirector($userId: Int!, $directorId: Int!) {
    deleteDirector(userId: $userId, directorId: $directorId)
  }
`;

const REMOVE_TRUST_MEMBER = gql`
  mutation deleteTrustMember($userId: Int!, $trustMemberId: Int!) {
    deleteTrustMember(userId: $userId, trustMemberId: $trustMemberId)
  }
`;

const REMOVE_JOINT_MEMBER = gql`
  mutation deleteJointMember($userId: Int!, $jointMemberId: Int!) {
    deleteJointMember(userId: $userId, jointMemberId: $jointMemberId)
  }
`;

const REMOVE_INVESTOR_ACCOUNT = gql`
  mutation RemoveInvestorAccount(
    $userId: Int!
    $investorAccountId: Int!
    $trustMemberId: Int
    $corporateMemberId: Int
    $jointMemberId: Int
  ) {
    removeInvestorAccount(
      userId: $userId
      investorAccountId: $investorAccountId
      trustMemberId: $trustMemberId
      corporateMemberId: $corporateMemberId
      jointMemberId: $jointMemberId
    )
  }
`;

const USERS_LIST_SUBSCRIPTION = gql`
  subscription usersListSubscription($userId: Int!, $roleId: Int!) {
    usersListSubscription(userId: $userId, roleId: $roleId)
  }
`;

const GET_SECTION_UPDATED_FIELDS = gql`
  query getSectionUpdatedFields($userId: Int!, $sectionName: String!, $accountId: Int) {
    getSectionUpdatedFields(userId: $userId, sectionName: $sectionName, accountId: $accountId)
  }
`;

export {
  GET_USER_DETAIL_BY_ID,
  GET_LENDING_CRETERIA,
  UPDATE_BENEFICIARY,
  REMOVE_BENEFICARY,
  UPDATE_DIRECTORS,
  REMOVE_DIRECTOR,
  REMOVE_INVESTOR_ACCOUNT,
  USERS_LIST_SUBSCRIPTION,
  GET_SECTION_UPDATED_FIELDS,
  UPDATE_JOINT_MEMBER,
  UPDATE_TRUST_MEMBER,
  REMOVE_TRUST_MEMBER,
  REMOVE_JOINT_MEMBER
};
