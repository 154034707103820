import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import CombineErrorMessage from 'components/CombineError';
import CustomPhoneInput from 'components/common/Fields/CustomPhoneInput';
import FormikInputField from 'components/common/Fields/FormikInputField';
import VerifyModal from './VerifyModal';
import {
  SEND_VERIFICATION_OTP,
  VERIFY_EMAIL_PHONENUMBER_OTP
} from 'pages/OnBoarding/OnBoardingGraphQL';
import { SUPER_ADMIN_ID } from 'constant';
import { getUser } from 'utils/UserDetails';

export const CommonForm = ({ isNewData, userPersonalDetails }) => {
  const [updatedUserData, setUpdatedUserData] = useState(userPersonalDetails || {});
  const [hasError, setHasError] = useState(false);
  const [verifyModal, setVerifyModal] = useState(false);
  const [formType, setFormType] = useState(null);
  const formikContext = useFormikContext();
  const { values, errors } = formikContext || {};
  const user = getUser();

  const { id: userId } = updatedUserData || {};
  const { roleId } = user;

  const [sendVerificationOtp] = useMutation(SEND_VERIFICATION_OTP);
  const [verifyEmailPhoneNumberOtp] = useMutation(VERIFY_EMAIL_PHONENUMBER_OTP);

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [formikContext?.errors, formikContext?.touched]);

  const checkError = () => {
    if (formikContext?.errors && formikContext?.touched) {
      return (
        (formikContext?.errors.firstName && formikContext?.touched.firstName) ||
        (formikContext?.errors.lastName && formikContext?.touched.lastName) ||
        (formikContext?.errors.preferredName && formikContext?.touched.preferredName) ||
        (formikContext?.errors.phoneNumber && formikContext?.touched.phoneNumber) ||
        (formikContext?.errors.email && formikContext?.touched.email)
      );
    }
  };

  const openCloseVerifyModal = async (type) => {
    setFormType(type);
    setVerifyModal(!verifyModal);

    try {
      await sendVerificationOtp({
        variables: {
          type: type,
          phoneNumber: values.phoneNumber,
          email: values.email
        }
      });
    } catch (err) {
      console.log('error: ', err);
    }
  };

  const submitOtp = async (otp, setError) => {
    if (otp.length !== 6) return setError(true);
    try {
      const result = await verifyEmailPhoneNumberOtp({
        variables: {
          value: formType === 'email' ? values?.email : values?.phoneNumber,
          otp: otp,
          userId: parseInt(userId),
          type: formType
        }
      });

      if (result) {
        const { data } = result;
        setUpdatedUserData({
          ...updatedUserData,
          phoneNumber: values.phoneNumber,
          email: values.email
        });
        toast.success(data?.verifyEmailOrPhoneNumberOTP?.message);
      }
      openCloseVerifyModal();
    } catch (err) {}
  };

  return (
    <Card className="mb-4">
      <CardBody>
        {hasError && (
          <CombineErrorMessage>Please ensure you enter all required fields</CombineErrorMessage>
        )}
        <div className="d-flex justify-content-between align-items-center p-0">
          <h3 className="card-heading">Personal Information</h3>
          {isNewData ? <p className="text-primary font-weight-bold">New Data</p> : null}
        </div>
        <div>
          <Row>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                id="firstName"
                name="firstName"
                type="text"
                component={FormikInputField}
                label="Legal First Name"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={6} lg={6}>
              <Field
                size="lg"
                id="lastName"
                name="lastName"
                type="text"
                component={FormikInputField}
                label="Legal Last Name"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                id="preferredName"
                name="preferredName"
                type="text"
                component={FormikInputField}
                label="Preferred Name"
                isError={false}
              />
            </Col>
            <Col className="input-spacing" md={8} lg={8}>
              <Field
                size="lg"
                id="phoneNumber"
                name="phoneNumber"
                label="Mobile"
                placeholder="Enter Your Mobile Number"
                component={CustomPhoneInput}
                isverified="true"
                isError={false}
                isCorrectValue={
                  values.phoneNumber != updatedUserData.phoneNumber &&
                  errors &&
                  !errors.phoneNumber &&
                  roleId != SUPER_ADMIN_ID
                }
                isVerifiedValue={
                  values.phoneNumber === updatedUserData.phoneNumber &&
                  errors &&
                  !errors.phoneNumber &&
                  roleId != SUPER_ADMIN_ID
                }
                openCloseVerifyModal={() => openCloseVerifyModal('phoneNumber')}
              />
            </Col>

            <Col className="input-spacing" md={12} lg={12}>
              <Field
                size="lg"
                id="email"
                name="email"
                type="text"
                component={FormikInputField}
                label="Email"
                // isverified={isEmailVerified}
                isverified="true"
                isError={false}
                openCloseVerifyModal={() => openCloseVerifyModal('email')}
                isVerificationEnabled={true}
                isCorrectValue={
                  values.email != updatedUserData.email &&
                  errors &&
                  !errors.email &&
                  roleId != SUPER_ADMIN_ID
                }
                isVerifiedValue={
                  values.email === updatedUserData.email &&
                  errors &&
                  !errors.email &&
                  roleId != SUPER_ADMIN_ID
                }
              />
            </Col>
          </Row>
        </div>
        <div>
          {verifyModal && (
            <VerifyModal
              title="Email Verification"
              open={verifyModal}
              close={openCloseVerifyModal}
              value={formType === 'email' ? values?.email : values?.phoneNumber}
              formType={formType}
              submitOtp={submitOtp}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};
