import React, { useEffect, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import { Card, CardBody, Label } from 'reactstrap';
import { useMediaQuery } from 'react-responsive';
import CombineErrorMessage from 'components/CombineError';
import FormikInputField from 'components/common/Fields/FormikInputField';
import { find } from 'lodash';
import { isMobileBrakePoint } from 'utils/responsiveBrakePoints';

export const maximumLtvOnLocation = [
  { label: 'Greater Toronto Area', value: null, isShow: false },
  { label: 'London Area', value: null, isShow: false },
  { label: 'Niagara Area', value: null, isShow: false },
  { label: 'Sarnia Area', value: null, isShow: false },
  { label: 'Hamilton Area', value: null, isShow: false },
  { label: 'Barrie Area', value: null, isShow: false },
  { label: 'Owen Sound', value: null, isShow: false },
  { label: 'Peterborough', value: null, isShow: false },
  { label: 'Kingston', value: null, isShow: false },
  { label: 'Ottawa', value: null, isShow: false },
  { label: 'Northern Ontario', value: null, isShow: false }
];

const MaxLTVLocation = () => {
  const [hasError, setHasError] = useState(false);
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext();
  const isMobile = useMediaQuery({ query: `(${isMobileBrakePoint})` });
  const { maximumLTVOnLocation } = values;

  useEffect(() => {
    const isError = checkError();
    setHasError(isError);
  }, [errors, touched, values.maximumLTVOnLocation]);

  const checkError = () => {
    const { maximumLTVOnLocation } = values;
    if (typeof errors.maximumLTVOnLocation === 'string' && touched.maximumLTVOnLocation) {
      return true;
    }
    if (touched.maximumLTVOnLocation) {
      const findMax = find(
        maximumLTVOnLocation,
        (item, index) =>
          item.isShow === true && touched.maximumLTVOnLocation?.[index]?.value && !item.value
      );

      return findMax ? true : false;
    }
  };

  const onChangeLoaction = (index) => {
    if (!values?.maximumLTVOnLocation?.[index].isShow) {
      setFieldValue(`maximumLTVOnLocation[${index}].value`, null);
      setFieldTouched(`maximumLTVOnLocation[${index}].value`, null);
    }
  };

  const getField = (label, isShow, index) => {
    return (
      <div
        key={label}
        className={`${
          isMobile ? `d-block mb-2` : 'd-flex'
        } justify-content-between align-items-center`}
        style={{
          minHeight: '65px',
          borderBottom: `${
            index + 1 === maximumLTVOnLocation.length ? '0px' : isMobile ? '0px' : '1px'
          } solid #dedede`
        }}>
        <Label
          className={`font-size-16 d-flex align-items-center ${isMobile ? 'mb-2' : 'mb-0'}`}
          onClick={() => onChangeLoaction(index)}>
          <Field
            size="lg"
            key={label}
            name={`maximumLTVOnLocation[${index}].isShow`}
            type="checkbox"
            className="me-2"
            style={{
              height: '17px',
              width: '17px'
            }}
          />
          {label}
        </Label>
        {isShow && (
          <div className={`${isMobile ? 'me-0' : 'me-5'}`}>
            <Field
              size="lg"
              id="sasas"
              name={`maximumLTVOnLocation[${index}].value`}
              type="number"
              component={FormikInputField}
              placeholder="LTV"
              grouptextpercentage="true"
              isError={false}
              style={{
                width: isMobile ? '60px' : '',
                borderColor:
                  errors.maximumLTVOnLocation?.[index]?.value &&
                  touched.maximumLTVOnLocation?.[index]?.value
                    ? '#ea6666'
                    : '#ced4da'
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const checkNumberError = () => {
    if (typeof errors.maximumLTVOnLocation === 'object') {
      return 'Please ensure you enter an LTV that is between 0 to 100 for any location you have selected.';
    } else {
      return 'You must set a maximum LTV for at least one location';
    }
  };

  return (
    <div className="mt-3">
      <Card>
        <CardBody className="p-4">
          {hasError && <CombineErrorMessage>{checkNumberError()}</CombineErrorMessage>}
          <h3 className="card-heading">Maximum LTV based on location</h3>
          <Field
            size="lg"
            Array
            name="maximumLTVOnLocation"
            render={() => {
              return maximumLTVOnLocation.map((item, index) => {
                const { label, isShow } = item;
                return getField(label, isShow, index);
              });
            }}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default MaxLTVLocation;
